import { useEffect, useState, useContext } from "react";
import { AppBar, Button, IconButton, Toolbar, Typography } from "@mui/material";
import { Box, Container } from "@mui/system";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import Snackbar from "../../components/Snackbar";
import PageLoader from "../../components/PageLoader";

import UserContext from "../../services/UserContext";
import RefundModal from "./RefundModal";
import RefundModalShopify from "./RefundModalShopify";
import HoldModal from "./HoldModal";
import ReleaseModal from "./ReleaseModal";
import AddOrderModal from "./AddOrderModal";
import AddRemoveCreditModal from "./AddRemoveCreditModal";
import RemoveUserDetailsModal from "./RemoveUserDetailsModal";
import CancelShopifyOrderModal from "./CancelShopifyOrderModal";

export default function User() {
  const [email, setEmail] = useState("");
  const [actionType, setActionType] = useState("");
  const [openToaster, setOpenToaster] = useState(false);
  const [toasterMessage, setToasterMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { userId } = useParams();
  const { userData } = useContext(UserContext);

  useEffect(() => {
    if (!userData) {
      navigate("/");
    } else {
      const { id, token, email } = userData;

      setEmail(email);
      if (id === parseInt(userId)) {
        setTimeout(() => {
          const win = document.getElementsByTagName("iframe")[0].contentWindow;
          win.postMessage(
            JSON.stringify({
              key: "tcc-jwt-token",
              data: token,
            }),
            "*"
          );
          setLoading(false);
          //win.postMessage(JSON.stringify({ key: "refresh" }), "*");
        }, 5000);
      }
    }
  }, [userData, userId, navigate]);

  const handleCloseToaster = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToasterMessage("");
    setOpenToaster(false);
  };

  const handleOpenToaster = (message) => {
    setOpenToaster(true);
    setToasterMessage(message);
  };

  const handleClose = () => {
    setActionType("");
  };

  const handleRefresh = (e) => {
    e.preventDefault();
    const win = document.getElementsByTagName("iframe")[0].contentWindow;
    win.postMessage(JSON.stringify({ key: "refresh" }), "*");
  };

  const handleLogout = (e) => {
    e.preventDefault();
    const win = document.getElementsByTagName("iframe")[0].contentWindow;
    win.postMessage(JSON.stringify({ key: "logout" }), "*");
  };

  return (
    <>
      <RefundModal
        open={actionType === "refund"}
        handleClose={handleClose}
        handleOpenToaster={handleOpenToaster}
      />
      <RefundModalShopify
        open={actionType === "refundShopifyOrder"}
        handleClose={handleClose}
        handleOpenToaster={handleOpenToaster}
      />
      <HoldModal
        open={actionType === "hold"}
        handleClose={handleClose}
        handleOpenToaster={handleOpenToaster}
      />
      <ReleaseModal
        open={actionType === "release"}
        handleClose={handleClose}
        handleOpenToaster={handleOpenToaster}
      />
      <AddOrderModal
        open={actionType === "addOrder"}
        handleClose={handleClose}
        handleOpenToaster={handleOpenToaster}
      />
      <AddRemoveCreditModal
        open={actionType === "addCredit"}
        handleClose={handleClose}
        handleOpenToaster={handleOpenToaster}
        type="Add Credit"
      />
      <AddRemoveCreditModal
        open={actionType === "removeCredit"}
        handleClose={handleClose}
        handleOpenToaster={handleOpenToaster}
        type="Remove Credit"
        userId={userId}
      />
      <RemoveUserDetailsModal
        open={actionType === "removeUserDetails"}
        handleClose={handleClose}
        handleOpenToaster={handleOpenToaster}
        userId={userId}
      />
      <CancelShopifyOrderModal
        open={actionType === "cancelShopifyOrder"}
        handleClose={handleClose}
        handleOpenToaster={handleOpenToaster}
        userId={userId}
      />
      <Snackbar
        open={openToaster}
        handleClose={handleCloseToaster}
        message={toasterMessage}
      />
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => navigate("/")}
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Back
          </Typography>
          <Typography>
            Current User | Email: {email} | User Id: {userId}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container
        maxWidth="lg"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
        height="100vh"
      >
        <Box
          sx={{
            display: "flex",
            gap: 2,
            flexWrap: "wrap",
            width: "100vw",
            padding: "30px 40px",
          }}
        >
          <Button variant="contained" onClick={() => setActionType("refund")}>
            Refund Order
          </Button>
          <Button variant="contained" onClick={() => setActionType("hold")}>
            Hold Order
          </Button>
          <Button variant="contained" onClick={() => setActionType("release")}>
            Release Order
          </Button>
          <Button variant="contained" onClick={() => setActionType("addOrder")}>
            Add Immediate Order
          </Button>
          <Button
            variant="contained"
            onClick={() => setActionType("addCredit")}
          >
            Add Credit
          </Button>
          <Button
            variant="contained"
            onClick={() => setActionType("removeCredit")}
          >
            Remove Credit
          </Button>
          <Button
            variant="contained"
            onClick={() => setActionType("removeUserDetails")}
          >
            Remove User Details
          </Button>
          <Button
            variant="contained"
            onClick={() => setActionType("cancelShopifyOrder")}
          >
            Cancel Shopify Order
          </Button>
          <Button
            variant="contained"
            onClick={() => setActionType("refundShopifyOrder")}
          >
            Partial Refund Shopify Order
          </Button>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              columnGap: "20px",
              marginBottom: "20px",
            }}
          >
            <Button variant="contained" onClick={handleRefresh}>
              Refresh
            </Button>
            <Button variant="contained" onClick={handleLogout}>
              Logout User
            </Button>
            {/* <Button
              variant="contained"
              onClick={() => {
                const { token } = userData;
                const win =
                  document.getElementsByTagName("iframe")[0].contentWindow;
                win.postMessage(
                  JSON.stringify({
                    key: "tcc-jwt-token",
                    data: token,
                  }),
                  "*"
                );
              }}
            >
              Test
            </Button> */}
          </Box>
          {loading && (
            <Box
              sx={{
                width: "1100px",
                flex: "none",
              }}
            >
              <PageLoader />
            </Box>
          )}
          <iframe
            src={process.env.REACT_APP_MAIN_APP}
            title="TCC"
            height="1000px"
            width="1100px"
            style={{
              border: "none",
              display: loading ? "none" : "block",
            }}
          />
        </Box>
      </Container>
    </>
  );
}
