import React, { useState, useEffect } from "react";
import {
  Modal,
  Button,
  Typography,
  TextField,
  Box,
  InputLabel,
  TextareaAutosize,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { refundOrderReasons } from "./constant";
import { makeStyles } from "@mui/styles";
import { useMutation } from "@apollo/client";
import { REFUND_SHOPIFY_ORDER_MUTATION } from "../../graphql/mutation/user";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: "white",
    padding: "30px",
    outline: "none",
    borderRadius: "10px",
  },
  dropdownContainer: {
    margin: "20px 0",
    textAlign: "left",
  },
  textArea: {
    marginTop: "20px",
    padding: "10px",
    borderColor: "#0000003b",
    outline: "none",
    borderRadius: "5px",
  },
}));

function getModalStyle() {
  return {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  };
}

const initialState = {
  id: "",
  amount: 0,
};

function AdminActionsModal(props) {
  const { open, handleClose, handleOpenToaster } = props;
  const [inputs, setInputs] = useState(initialState);
  const [error, setError] = useState("");

  useEffect(() => {
    if (!open) {
      setInputs({ ...initialState });
      setError("");
    }
  }, [open]);

  const [refundAmount, { loading }] = useMutation(REFUND_SHOPIFY_ORDER_MUTATION);

  const styles = useStyles();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await refundAmount({
        variables: {
          input: {
            id: inputs.id,
            amount: parseInt(inputs.amount),
          },
        },
      });
      const isSuccess = response?.data?.refundShopifyOrder;
      if (isSuccess) {
        handleClose();
        setInputs({ ...initialState });
        handleOpenToaster("Shopify refund successful!");
      }
    } catch (err) {
      setError(err.message);
    }
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className={styles.paper} style={getModalStyle()}>
          <Box sx={{ position: "relative" }}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              Refund Shopify Order
            </Typography>

            <CloseIcon
              style={{
                backgroundColor: "rgba(0,0,0,0.12)",
                borderRadius: "20px",
                position: "absolute",
                right: 0,
                top: "5px",
                cursor: "pointer",
              }}
              onClick={handleClose}
            />
          </Box>

          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              label="Internal / Shopify Order Id"
              name="id"
              autoComplete="id"
              autoFocus
              onChange={handleChange}
              value={inputs.id}
              required
            />
            <TextField
              margin="normal"
              fullWidth
              label="Refund Amount (in cents)"
              name="amount"
              autoComplete="amount"
              autoFocus
              onChange={handleChange}
              value={inputs.amount}
              required
            />
            {error && (
              <Box>
                <Typography component="p" variant="p" sx={{ color: "red" }}>
                  {error}
                </Typography>
              </Box>
            )}
            <Button
              variant="contained"
              sx={{
                margin: "20px 0",
              }}
              type="submit"
              onClick={handleSubmit}
              disabled={
                !inputs.amount ||
                !inputs.id ||
                loading
              }
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
}

export default AdminActionsModal;
